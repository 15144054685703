.App {
  display: flex;
  flex-direction: column;
  text-align: center;
  min-width: 100vw;
  min-height: 100vh;
  background-color: #000000;
  color: #ffffff;
}

.Body {
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: #000000;
  min-width: 100vw;
  min-height: 90vh;
  color: #ffffff;
  font-size: '16px';
}


.navbar {
	width: 100%;
	padding: 10px 20px;
	background-color: #e00000;
	display: flex;
	height: 10vh;
	align-items: center;
	justify-content: space-between;
}
.navbar > h2 {
	color: #fffbf5;
}


